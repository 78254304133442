section#cajitas {
	display: flex;
	justify-content: space-evenly;
	position: relative;
	top: -50px;
	margin-bottom: 100px;
}
section#cajitas > .caja {
	background: white;
	width: 16%;
	padding: 10px 20px;
	box-shadow: 4px 6px 35px -22px black;
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
}
section#cajitas > .caja:hover {
	animation: 0.4s arribaabajo ease-in-out;
}
section#cajitas > .caja > img {
	width: 80px;
	-webkit-transition: all 0.2s ease-out;
	-moz-transition:    all 0.2s ease-out;
	-o-transition:      all 0.2s ease-out;
	transition:         all 0.2s ease-out;
}
section#cajitas > .caja:hover > img {
	transform: rotate(15deg);
}
section#cajitas > .caja > h3 {
	text-align: center;
	margin-bottom: 30px;
}
section#cajitas > .caja > ul {
	margin: 0;
	padding: 0;
}
section#cajitas > .caja > ul > li {
	list-style: none;
	margin: 0;
	padding: 0;
	text-align: center;
	margin-bottom: 10px;
}

@keyframes arribaabajo {
	0% { top: 0; }
	50% { top: 10px; }
	100% { top: 0; }
}

@media ( max-width: 850px ) {
	section#cajitas {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		margin-bottom: 30px;
	}
	section#cajitas > .caja {
		width: 80%;
		display: flex;
		flex-direction: row;
		min-height: 210px;
		margin: 20px 0;
	}
	section#cajitas > .caja > h3 ,
	section#cajitas > .caja > ul {
		left: 120px;
		position: absolute;
	}
	section#cajitas > .caja > h3 {
		text-align: left;
		top: 5px;
	}
	section#cajitas > .caja > ul {
		top: 90px;
	}
	section#cajitas > .caja > ul > li {
		text-align: left;
		margin: 0;
	}
}