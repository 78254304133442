footer {
	background: #023368;
	margin-top: 100px;
}
footer > a {
	padding: 10px 0;
	color: white;
	display: flex;
	text-decoration: none;
	width: 100%;
	flex-direction: row;
	justify-content: space-around;
}
footer > a > p {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
	text-align: center;
	width: 100%;
	font-size: .7em;
	letter-spacing: 0.05em;
}
footer > a > p > img {
	width: 20px;
	margin: 0 10px;
}
footer > a > p > strong {
	margin-left: 10px;
}