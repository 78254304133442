a.buttonComponent {
	background: #004aad;
	color: white;
	height: 30px;
	display: flex;
	padding: 20px 0;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-around;
	align-items: center;
	width: 260px;
	text-decoration: none;
	font-size: 1.4em;
	text-transform: uppercase;
	font-weight: 600;
	letter-spacing: 0.05em;
	overflow: hidden;
	position: relative;
}
a.buttonComponent > span.barrita {
	background-color: #0457c7;
	display: block;
	width: 30px;
	height: 130px;
	position: absolute;
	z-index: 0;
	transform: rotate(-25deg);
	left: -60px;
	-webkit-transition: all 0.3s ease-out;
	-moz-transition:    all 0.3s ease-out;
	-o-transition:      all 0.3s ease-out;
	transition:         all 0.3s ease-out;
}
a.buttonComponent:hover > span.barrita {
	left: 280px;
}
a.buttonComponent > p {
	z-index: 1;
	display: flex;
	justify-content: space-evenly;
	width: 100%;
}
a.buttonComponent > p > span {
	display: block;
}
a.buttonComponent > p > img {
	width: 20px;
	z-index: 1;
	position: relative;
	-webkit-transition: all 0.2s ease-out;
	-moz-transition:    all 0.2s ease-out;
	-o-transition:      all 0.2s ease-out;
	transition:         all 0.2s ease-out;
}
a.buttonComponent:hover > p > img {
	left: 10px;
}

@media ( max-width: 850px ) {
	a.buttonComponent {
		width: 190px;
		height: 10px;
		font-size: 1em;
	}
}