#contacto {
	background: white;
}
#contacto h3 {
	text-align: center;
	text-transform: uppercase;
	font-size: 3em;
	letter-spacing: 0.05em;
}
#contacto > div {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
}
#contacto > div > div.direcciones .bloques {
	display: flex;
	flex-wrap: wrap;
}
#contacto > div > div.direcciones .bloques .bloque {
	display: flex;
	flex-direction: column;
	align-items: center;
	border: 1px solid #ddd;
	margin: 0 2% 1%;
	padding: 2% 0%;
	width: 40%;
}
#contacto > div > div.direcciones .bloques .bloque > img {
	width: 60px;
}
#contacto > div > div.direcciones .bloques .bloque > a {
	text-align: center;
	text-decoration: none;
	color: black;
}
#contacto > div form {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 10px 0 40px;
}
#contacto > div form input ,
#contacto > div form textarea {
	width: 200px;
	margin-bottom: 10px;
	height: 40px;
	padding: 5px 10px;
	outline: none;
}
#contacto > div form textarea {
	height: 100px;
}
#contacto > div form input.invalido ,
#contacto > div form textarea.invalido {
	border: 1px solid red;
}
#contacto > div form button {
	width: 220px;
	height: 40px;
	background-color: var( --azul );
	border: 1px solid var(--azulOscuro);
	color: white;
	cursor: pointer;
	-webkit-transition: all 0.2s ease-out;
	-moz-transition:    all 0.2s ease-out;
	-o-transition:      all 0.2s ease-out;
	transition:         all 0.2s ease-out;
}
#contacto > div form button:hover {
	background: var(--azulOscuro);
}
#contacto > div form .estadoEnvio > p {
	padding: 20px;
	text-align: center;
}
#contacto > div form .estadoEnvio > p.true {
	color: #155724;
	background-color: #d4edda;
	border-color: #c3e6cb;
}
#contacto > div form .estadoEnvio > p.false {
	color: #856404;
	background-color: #fff3cd;
	border-color: #ffeeba;
}

@media ( max-width: 850px ) {
	#contacto > h3 {
		font-size: 9.2vw;
		padding-top: 20px;
	}
	#contacto > div {
		display: flex;
		justify-content: space-evenly;
		flex-direction: column;
	}
	#contacto > div > div.direcciones {
		margin-bottom: 30px;
	}
	#contacto > div > div.direcciones .bloques {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-evenly;
	}
	#contacto > div > div.direcciones .bloques .bloque {
		margin-bottom: 20px;
		margin: 0 1% 1%;
		width: 47%;
	}
	#contacto > div > div.direcciones .bloques .bloque > a {
		font-size: .9em;
	}
}