@import url('https://fonts.googleapis.com/css2?family=Jost:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root {
	--azulClaro:    #0457c7;
	--azul:         #004aad;
	--azulOscuro:   #023368;
}

body {
	margin: 0;
	font-family: 'Jost', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background: whitesmoke;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
	monospace;
}
