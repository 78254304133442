header {
	background: white;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
header .logo {
	display: flex;
}
header .logo a {
	width: 200px;
	margin: 10px 0 10px 20px;
}
header .logo a img {
	width: 100%;
}
header .menu ul {
	display: flex;
	margin: 0 20px 0 0;
	padding: 0;
}
header .menu ul li {
	list-style: none;
	margin: 0 0 0 20px;
	padding: 0;
}
header .menu ul li a {
	text-transform: uppercase;
	text-decoration: none;
	color: black;
	letter-spacing: 0.05em;
	font-size: 1em;
	font-weight: 500;
	border-bottom: 4px solid transparent;
	-webkit-transition: all 0.2s ease-out;
	-moz-transition:    all 0.2s ease-out;
	-o-transition:      all 0.2s ease-out;
	transition:         all 0.2s ease-out;
}
header .menu ul li a.tamoactivo ,
header .menu ul li a:hover {
	border-bottom: 4px solid black;
}

@media ( max-width: 850px ) {
	header .logo a {
		width: 90px;
	}
	header .elmenu {
		display: flex;
		flex-direction: row;
		align-items: center;
		z-index: 3;
	}
	header .elmenu > p {
		font-weight: 600;
		letter-spacing: 0.05em;
		margin-right: 10px;
	}
	header .elmenu > div {
		width: 40px;
		height: 25px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		position: relative;
	}
	header .elmenu > div > span {
		background: black;
		height: 4px;
		width: 70%;
		display: block;
	}
	body.menuactivo .elmenu > div > span {
		top: 10px;
		position: absolute;
	}
	body.menuactivo .elmenu > div > span:nth-child( 1 ) {
		transform: rotate(-45deg);
	}
	body.menuactivo .elmenu > div > span:nth-child( 2 ) {
		display: none;
	}
	body.menuactivo .elmenu > div > span:nth-child( 3 ) {
		transform: rotate(45deg);
	}
	header nav {
		display: none;
	}
	body.menuactivo nav {
		display: block;
		position: absolute;
		right: 0;
		background: white;
		height: 100%;
		z-index: 2;
		top: 0;
		right: 0;
		width: 80%;
	}
	body.menuactivo nav ul {
		margin: 0;
		padding: 0;
		height: 100%;
		margin-top: 100px;
	}
	body.menuactivo nav ul li {
		list-style: none;
		margin: 0;
		padding: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 50px;
		border-bottom: 1px solid #eee;
	}
	body.menuactivo nav ul li a {
		width: 100%;
		display: block;
		text-align: right;
		color: black;
		text-decoration: none;
		font-size: 1.3em;
		position: relative;
		right: 10px;
		text-transform: uppercase;
		height: 100%;
		line-height: 2.5em;
		letter-spacing: 0.05em;
	}
	body.menuactivo nav ul li a.tamoactivo {
		font-weight: 600;
	}
	header .menufondoopacidad {
		display: none;
	}
	body.menuactivo header .menufondoopacidad {
		background: black;
		width: 100%;
		height: 100%;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 1;
		display: block;
		opacity: 0.6;
	}
}