section#inicio {
	position: relative;
	/* background-image: url("../../assets/imagenes/portada1_opacidad.jpg"); */
	/* background-image: url("../../assets/imagenes/portada1_opacidad-35.jpg"); */
	background-image: url("../../assets/imagenes/portada1_opacidad-50.jpg");
	background-size: cover;
	padding: 100px 0 180px;
}
section#inicio > div {
	display: flex;
	flex-direction: column;
	align-items: center;
}
section#inicio > div > h2 {
	color: white;
	font-size: 8em;
	text-align: center;
	padding: 0 40px;
	text-transform: uppercase;
	line-height: 1em;
	margin-bottom: 10px;
}
section#inicio > div > h2 > span {
	color: #ff9318;
}
@media ( max-width: 850px ) {
	section#inicio {
		padding: 0 0 50px;
	}
	section#inicio > div > h2 {
		font-size: 11vw;
	}
}